.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 55%;
}

.w85 {
  width: 85%;
}

.w45 {
  width: 45%;
}

.logoutButton {
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: center;
  border: 2px solid #cf000099;
  color: #cf0000;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 37px;
  background-color: transparent;
}

.logoutModalContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.editTitle {
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: right;
  color: #00a0ff;
}

.modalText {
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: right;
  color: #31373a;
}
