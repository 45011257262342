.character {
  position: absolute;
  top: 0;
  left: 5%;
  /* width: 100%; */
}

.room {
  position: relative;
  width: 100%;
}

.doors {
  position: absolute;
  left: 16%;
  top: 52%;
  width: 68%;
}

.doorsOpen {
  position: absolute;
  left: 5%;
  width: 68%;
}

.characterImg {
  position: absolute;
  top: 77%;
  height: 22%;
  left: 37%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.thoughts {
  position: absolute;
}

.doorsContainer {
  position: absolute;
  left: 11%;
  top: 52%;
  width: 100%;
  height: 100%;
}

.messageContainer {
  position: absolute;
  left: 7%;
  top: 0;
  animation: fromLeft 0.7s ease-in-out;
}

.messageContainerBig,
.messageContainerBigNotAnimated {
  position: absolute;
  left: -38px;
  top: -235px;
  height: 111%;
}

.messageContainerBig {
  animation: grow 0.7s ease-in-out;
}

.modalText {
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: right;
  color: #31373a;
}

.modalButton {
  background-color: #00a0ff;
  border: none;
  border-radius: 37px;
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: center;
  color: #ffffff;
  padding: 15px;
  width: 80%;
}

@keyframes grow {
  from {
    left: -77px;
    top: -110px;
    transform: scale(0.3);
  }
  75% {
    left: -38px;
    top: -235px;
    transform: scale(1.05);
    opacity: 1;
  }
  to {
    left: -38px;
    top: -235px;
    transform: scale(1);
    opacity: 1;
  }
}

.messageContainerBig textarea,
.messageContainerBigNotAnimated textarea {
  position: absolute;
  left: 18%;
  top: 14%;
  height: 21%;
  width: 50%;
  border: none;
  border-radius: 4%;
  text-align: center;
  font-family: "Vazir";
  font-size: 12px;
  font-weight: 700;
  line-height: 15.95px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.messageContainerBig textarea::placeholder,
.messageContainerBigNotAnimated textarea::placeholder,
.messageContainerGetSmall textarea::placeholder,
.messageContainerFadeOut textarea::placeholder {
  font-family: "Vazir";
  color: #000000;
  font-size: 12px;
  line-height: 17px;
  font-weight: 700;
  text-align: center;
}

.messageContainerBig textarea:focus,
.messageContainerBigNotAnimated textarea:focus,
.messageContainerGetSmall textarea:focus,
.messageContainerFadeOut textarea:focus {
  outline: none;
}

@keyframes fromLeft {
  from {
    transform: scale(0.5);
    opacity: 0.5;
    left: -50px;
  }
  75% {
    transform: scale(1);
    opacity: 1;
    left: 6%;
  }
  to {
    transform: scale(1);
    left: 5%;
  }
}

.messageContainerGetSmall {
  position: absolute;
  left: -6%;
  top: 0;
  height: 40%;
  width: 75%;
}

.messageContainerFadeOut {
  position: absolute;
  left: 65%;
  top: 5%;
  height: 11%;
  opacity: 0;
  animation: fadeOut 0.5s ease-in-out;
}

@keyframes fadeOut {
  from {
    left: 48%;
    top: 0;
    height: 35%;
    opacity: 1;
  }
  to {
    left: 65%;
    top: 5%;
    height: 11%;
    opacity: 0;
  }
}

.messageContainerGetSmall {
  animation: getSmall 0.5s ease-in-out;
}

@keyframes getSmall {
  from {
    left: -20%;
    top: -235px;
    height: 111%;
  }
  to {
    left: -6%;
    top: 0;
    height: 40%;
  }
}

.messageContainerGetSmall textarea,
.messageContainerFadeOut textarea {
  position: absolute;
  left: 73%;
  top: 12%;
  height: 13%;
  width: 18%;
  border: none;
  border-radius: 50%;
  text-align: center;
  font-family: "Vazir";
  font-size: 12px;
  font-weight: 700;
  line-height: 15.95px;
  text-align: center;
}

.messageContainer textarea {
  position: absolute;
  left: 8%;
  top: 35%;
  height: 30%;
  width: 71%;
  border: none;
  border-radius: 50%;
  text-align: center;
}

.messageContainer textarea::placeholder {
  font-family: "Vazir";
  color: #000000;
  font-size: 12px;
  line-height: 17px;
  font-weight: 700;
}

.face,
.faceNotAnimated {
  position: absolute;
  left: 10px;
  top: 14px;
  height: 19%;
}

.faceLookRU {
  position: absolute;
  left: 23%;
  top: 14px;
  height: 19%;
  animation: lookRU 0.5s ease-in-out;
}

@keyframes lookRU {
  from {
    left: 10px;
    top: 14px;
    opacity: 0.7;
  }
  to {
    left: 23%;
    top: 14px;
    opacity: 1;
  }
}

.faceLookF {
  position: absolute;
  left: 15%;
  top: 21%;
  height: 19%;
  animation: lookF 0.5s ease-in-out;
}

@keyframes lookF {
  from {
    left: 23%;
    top: 14px;
    opacity: 0.7;
  }
  to {
    left: 15%;
    top: 21%;
    opacity: 1;
  }
}

.face {
  animation: face 0.5s ease-in-out;
}

@keyframes face {
  from {
    left: 18px;
    top: 25px;
    opacity: 0.5;
  }
  to {
    left: 10px;
    top: 14px;
    opacity: 1;
  }
}
