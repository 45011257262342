.character,
.characterNotAnimated {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70%;
  position: relative;
  width: 50%;
}

@media (max-height: 760px) {
  .sessionText {
    margin-bottom: 29px !important;
  }
  .characterMain {
    height: 26% !important;
  }
}

.characterMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  position: relative;
}

.characterMain p {
  color: #1b4965;
  font-family: "Vazir";
  font-size: 15px;
  font-weight: 500;
  line-height: 23.44px;
  text-align: center;
}

.characterMain img {
  width: 50%;
}

.character p,
.characterNotAnimated p {
  color: #1b4965;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  font-family: "Vazir";
}

.sessionText {
  height: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 50px 39px white;
  background-color: white;
  margin: 50px 0;
}

.sessionText p {
  color: #1b4965;
  font-size: 18px;
  font-weight: 500;
  line-height: 4vh;
  font-family: "Vazir";
}

.characterContainer .character img {
  height: 85%;
  animation: getSmall 0.7s ease-in-out;
}

.characterContainer .characterNotAnimated img {
  height: 100%;
}

.characterContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.circlesNotAnimated {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 23px;
  width: 50%;
  height: 100%;
}

.circles {
  opacity: 1;
  animation: appear 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 23px;
  width: 50%;
  height: 100%;
}

.circle,
.circleNotAnimated {
  background-color: #c6eaff;
  border-radius: 50%;
  height: 140px;
  width: 140px;
  position: relative;
}

.circle img,
.circleNotAnimated img {
  position: absolute;
}

.circleNotAnimated .brain {
  right: 30px;
  height: 80%;
}

.circle .brain {
  right: 30px;
  height: 80%;
  animation: brain 0.7s ease-in;
}

.circleNotAnimated .heart {
  right: 22%;
  top: 18%;
  height: 65%;
  transition: background-color 0.5s ease-in-out 0.5s;
}

.circle .heart {
  right: 25%;
  top: 30px;
  height: 65%;
  animation: heart 0.7s ease-in;
}

.faceRD {
  position: absolute;
  height: 30% !important;
  top: 30%;
  left: 29%;
  opacity: 1;
  animation: faceRd 0.3s ease-in-out;
}

.face {
  position: absolute;
  height: 18% !important;
  top: 26%;
  left: 14%;
  opacity: 1;
  animation: face 0.5s ease-in-out;
}

@keyframes face {
  from {
    top: 32%;
    left: 18%;
    opacity: 0.5;
  }
  75% {
    top: 26%;
    left: 13%;
    opacity: 0.9;
  }
  to {
    top: 26%;
    left: 14%;
    opacity: 1;
  }
}

@keyframes faceRd {
  from {
    top: 21%;
    left: 34%;
    opacity: 0.5;
  }
  to {
    top: 30%;
    left: 29%;
    opacity: 1;
  }
}

@keyframes getSmall {
  from {
    height: 125%;
  }
  75% {
    height: 80%;
  }
  to {
    height: 85%;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes heart {
  from {
    right: 155%;
    height: 20%;
  }
  75% {
    height: 45%;
  }
  to {
    right: 25%;
    top: 18%;
    height: 65%;
  }
}

@keyframes brain {
  from {
    right: 150%;
    height: 45%;
    top: 20%;
  }
  75% {
    height: 65%;
    top: 2%;
  }
  to {
    right: 22%;
    height: 80%;
    top: 0;
  }
}

.changeBackground {
  animation: backgroundColor 1.5s ease-in-out;
  background-color: #70caff;
}

@keyframes backgroundColor {
  from {
    background-color: #c6eaff;
  }
  75% {
    background-color: #c6eaff;
  }
  to {
    background-color: #70caff;
  }
}

@media (min-width: 345px) and (max-width: 389px) {
  .w60 {
    height: 45% !important;
  }
}

@media (min-width: 992px) {
  .circle,
  .circleNotAnimated {
    width: 73%;
    height: 49%;
  }
}
