.blueButton {
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: center;
  color: #ffffff;
  background-color: #00a0ff;
  border: none;
  border-radius: 37px;
  padding: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  gap: 10px;
}

.redButton {
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: center;
  color: #cf0000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-direction: row-reverse;
  background-color: transparent;
  border-color: transparent;
  width: 100%;
}
