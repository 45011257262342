.glass {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 10%;
  left: 0%;
  z-index: 1;
  background-color: #ffffffb2;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.video {
  width: 100%;
  height: 75%;
}

.video video {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}

@media (max-height: 760px) {
  .video {
    height: 70% !important;
  }
}

.background {
  povazir-events: none;
  position: absolute;
  z-index: 0;
  top: 6.5rem;
  width: 91%;
}

.backgroundNormal,
.backgroundNormalNotAnimated {
  height: 63vh;
  opacity: 1;
  background-size: cover;
}

.backgroundNormal {
  animation: background 0.6s ease-in-out;
}

@keyframes background {
  from {
    transform: scale(1.05);
    opacity: 0.7;
  }
  75% {
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.cardContainer {
  height: 35%;
  z-index: 2;
}

.characterSmall {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  position: relative;
}

.characterImg,
.characterImgNotAnimated {
  height: 100%;
  width: 100%;
}

.characterImg {
  animation: character 0.7s ease-in-out;
}

.modalText {
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: right;
  color: #31373a;
}

.modalButton {
  background-color: #00a0ff;
  border: none;
  border-radius: 37px;
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: center;
  color: #ffffff;
  padding: 15px;
  width: 80%;
}

@keyframes character {
  from {
    height: 250%;
    width: 50%;
    position: absolute;
    top: -233px;
    left: 100px;
  }
  50% {
    position: absolute;
    height: 145%;
    width: 30%;
    top: -17px;
    left: 0px;
  }
  75% {
    height: 141%;
    width: 30%;
    position: absolute;
    left: 0;
    top: -15px;
  }
  80% {
    height: 141%;
    width: 30%;
    position: absolute;
    left: 0;
    top: -15px;
  }
  85% {
    height: 135%;
    width: 26%;
    position: absolute;
    left: 0;
    top: -15px;
  }
  90% {
    height: 135%;
    width: 26%;
    position: absolute;
    left: 0;
    top: -15px;
  }
  95% {
    height: 135%;
    width: 26%;
    position: absolute;
    left: 0;
    top: -15px;
  }
  to {
    height: 100%;
    width: 100%;
    position: unset;
  }
}

.characterSmall p {
  color: #666666;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  animation: appear 0.8s linear;
  font-family: "Vazir";
  width: 70%;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.inputContiner {
  height: 26vh;
  margin-top: 12px;
}

.inputContiner textarea {
  background-color: #cae9ff99;
  border-radius: 4px;
  border: none;
  padding-top: 18px;
  padding-right: 23px;
  height: 135%;
  width: 94%;
}

.inputContiner textarea::placeholder {
  color: #1b4965cc;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  font-family: "Vazir";
}

.character,
.characterNotAnimated {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  position: relative;
  z-index: 2;
}

.character p,
.characterNotAnimated p {
  color: #1b4965;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  font-family: "Vazir";
}

.faceNormal {
  position: absolute;
  left: 13%;
  top: 22%;
  height: 21%;
  animation: faceNormal 0.5s ease-in-out;
}

@keyframes faceNormal {
  from {
    left: 16%;
    top: 10%;
    opacity: 0.7;
  }
  to {
    left: 13%;
    top: 22%;
    opacity: 1;
  }
}

.face {
  position: absolute;
  opacity: 1;
  left: 20%;
  top: 9%;
  height: 20%;
  animation: face 0.5s ease-in-out;
}

@keyframes face {
  from {
    opacity: 0.7;
    left: 12%;
    top: 16%;
  }
  to {
    opacity: 1;
    left: 20%;
    top: 9%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .background {
    width: auto;
    margin-right: -4%;
  }
}

@media (min-width: 992px) {
  .background {
    width: auto;
    padding-right: 2%;
  }
}
