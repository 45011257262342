.characterMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 45%;
  position: relative;
}

.characterMain img {
  width: 45%;
}

.carousel {
  position: absolute !important;
  top: 43%;
  border-radius: 50%;
  background-color: transparent;
  width: 68% !important;
  left: 16%;
  z-index: 0 !important;
  height: 50%;
}
.message {
  position: relative;
  height: 105%;
  width: 100%;
}

@media (max-height: 760px) {
  .message {
    height: 115%;
  }
  .carousel {
    top: 45%;
  }
}
