.header {
  background-color: #58abdc;
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header h1 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}

.header img {
  position: absolute;
  left: 25px;
}
