.session {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  width: 33%;
  margin-bottom: 20%;
}

@media (max-height: 760px) {
  .session {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    width: 33%;
    margin-bottom: 8%;
  }
}

.session a {
  text-decoration: none;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
}

.session div {
  border-radius: 50%;
  background-color: #cae9ff;
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.session div.done {
  background-color: #92e4c1;
  position: relative;
}

.session .tick {
  position: absolute;
  right: -1%;
  top: 5%;
}

.session p {
  margin-top: 5px;
}

.image {
  height: 55px;
  width: 55px;
}
