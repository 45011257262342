.container {
  background-color: #5fa8d3;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 2px 0;
  width: 100%;
}

.active {
  background-color: #96cceb;
  width: 75px;
  height: 78px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
