.button {
  font-family: "Vazir";
  background-color: #58abdc;
  border: none;
  border-radius: 66px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  line-height: 37px;
  padding: 10px 0;
  align-self: center;
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button img {
  position: absolute;
  right: 6%;
}
