.control {
  width: 90%;
  position: absolute;
  bottom: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

@media (min-width: 768px) {
  .control {
    width: 41%;
  }
}

@media (min-width: 1200px) {
  .control {
    width: 32%;
  }
}

@media (min-width: 1400px) {
  .control {
    width: 26%;
  }
}

@media (min-width: 1600px) {
  .control {
    width: 20%;
  }
}

@media (min-width: 1800px) {
  .control {
    width: 22%;
  }
}