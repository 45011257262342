@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: "Vazir";
  src: url("assets/fonts/Vazirmatn-Regular.ttf");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Vazir" !important;
}

p {
  margin: 0;
  padding: 0;
}
.rhap_main-controls-button {
  color: #58abdc !important;
}

.rhap_additional-controls,
.rhap_volume-controls {
  display: none !important  ;
}

.rhap_main-controls {
  align-self: center;
  width: 100%;
  gap: 5%;
}

.rhap_progress-filled {
  left: 0;
}

.rhap_container {
  background-color: #ffffffd6 !important;
  border-radius: 10px;
  border: none;
  margin-bottom: 7%;
  height: 40%;
}

.rhap_progress-indicator,
.rhap_progress-filled {
  background-color: #0f9cef !important;
}

.rhap_download-progress {
  background-color: #cae9ff !important;
}

.awssld__content {
  border-radius: 50%;
  background-color: transparent !important;
}
.Carousel_carousel__DiEnG {
  overflow: unset !important;
}
