.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000045;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background: #feece7;
  height: 222px;
  width: 85%;
  margin: auto;
  padding: 4%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: 1000;
}

.icon {
  position: absolute;
  top: -9%;
}

.button {
  background-color: #fcaf9a;
  color: white;
  font-family: "Vazir";
  font-size: 20px;
  font-weight: 500;
  line-height: 31.25px;
  text-align: center;
  border-radius: 50px;
  border: none;
  padding: 8px 40px;
}

.modal p {
  font-family: "Vazir";
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  text-align: center;

  color: #1b4965;
}
