.card {
  background-color: #feece7;
  padding: 35px 12px;
  position: relative;
  border-radius: 10px;
  z-index: 1;
  margin-top: 5%;
}

.badge {
  position: absolute;
  top: -20px;
  right: 43%;
}

.card p {
  font-family: "Vazir";
  font-size: 20px;
  font-weight: 500;
  line-height: 31.25px;
  text-align: right;
  color: #1b4965;
}

@media (max-height: 760px) {
  .card p {
    font-family: "Vazir";
    font-size: 16px;
    font-weight: 500;
    line-height: 28.25px;
    text-align: right;
    color: #1b4965;
  }
}

.expnadedCard p {
  font-family: "Vazir";
  color: #666666;
  font-size: 16px;
  font-weight: 600;
  line-height: 3.5vh;
  height: 85%;
  display: block;
  animation: fadeIn 0.5s ease-in-out;
  animation-delay: 0.2s;
}

.expnadedCard {
  background-color: #feece7;
  padding: 7% 5%;
  position: relative;
  border-radius: 10px;
  margin-top: 5%;
  height: 85%;
  animation: expand 0.5s ease-in-out;
}

@keyframes expand {
  from {
    height: 37%;
  }
  to {
    height: 85%;
  }
}

@keyframes fadeIn {
  from {
    height: 37%;
    display: none;
  }
  to {
    height: 85%;
    display: block;
  }
}

.back {
  display: flex;
  position: absolute;
  align-items: center;
  bottom: 18px;
  left: 39px;
  width: 13%;
  justify-content: space-between;
}

.back svg {
  height: 17px;
  width: 9px;
}

.back p {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  color: #666666;
}
