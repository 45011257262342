.card {
  background-color: #d1ecff;
  padding: 5% 5vw;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
  margin-bottom: 30px;
}

.card p {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  font-family: "Vazir";
}

.card button,
.card button a {
  background-color: #0077b6;
  text-decoration: none;
  border: none;
  border-radius: 37px;
  padding: 7px 26px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  font-family: "Vazir";
}

.range {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  height: 34vh;
}

.chart {
  position: absolute;
}

.mood {
  position: absolute;
  height: 45%;
}

.range div div svg circle:nth-of-type(1),
.range div div svg svg {
  display: none;
}

.range div div svg circle:nth-of-type(2) {
  r: 18 !important;
}

.range div div div {
  display: none;
}

.mood-status {
  margin-top: 4vh;
  text-align: center;
}

.mood-status p {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 3%;
}

.actions button {
  border-radius: 37px;
  border-color: #0077b699;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #0077b6cc;
  padding: 12px 56px;
  display: flex;
  align-items: center;
  position: relative;
}

.actions img {
  position: absolute;
  right: 20px;
}

.actions button:hover {
  cursor: poVazir;
}

.modalText {
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: right;
  color: #31373a;
}

.modalButton {
  background-color: #00a0ff;
  border: none;
  border-radius: 37px;
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: center;
  color: #ffffff;
  padding: 15px;
  width: 80%;
}
