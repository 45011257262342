.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 7%;
  height: 22%;
}

.header p {
  width: 49%;
  font-family: "Vazir";
  font-size: 20px;
  font-weight: 500;
  line-height: 31.25px;
  text-align: right;
  color: #1b4965;
}

.header div {
  width: 49%;
}

.header div img {
  width: 80%;
  height: 100%;
}

.listening {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.listening img {
  width: 35%;
  position: absolute;
  animation: fromLeft 0.5s ease-in-out;
}

.modalText {
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: right;
  color: #31373a;
}

.modalButton {
  background-color: #00a0ff;
  border: none;
  border-radius: 37px;
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: center;
  color: #ffffff;
  padding: 15px;
  width: 80%;
}

@keyframes fromLeft {
  from {
    width: 35%;
    position: absolute;
    height: 100%;
    left: 5%;
  }
  75% {
    width: 52%;
    position: absolute;
    height: 100%;
    left: 25%;
  }

  to {
    position: absolute;
    left: unset;
    width: 55%;
  }
}

.characterMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  position: relative;
}

.characterMain p {
  font-family: "Vazir";
  font-size: 15px;
  font-weight: 500;
  line-height: 23.44px;
  text-align: center;
  color: #1b4965;
}

.characterMain div {
  position: relative;
  height: 65%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.characterMain img {
  width: 50%;
}

.face {
  position: absolute;
  height: 17% !important;
  top: 30%;
  left: 26%;
  width: auto !important;
}

.faceUp {
  position: absolute;
  height: 19% !important;
  top: 10%;
  left: 34%;
  width: auto !important;
  animation: faceUp 0.5s ease-in-out;
}

@keyframes faceUp {
  from {
    top: 25%;
    opacity: 0.7;
  }
  to {
    top: 14%;
    opacity: 1;
  }
}

.inputContiner {
  height: 26vh;
  margin-top: 12px;
}

.inputContiner textarea {
  background-color: #ffffffd6;
  border-radius: 4px;
  border: none;
  padding-top: 18px;
  padding-right: 23px;
  height: 127%;
  width: 94%;
}

.inputContiner textarea::placeholder {
  color: #1b4965cc;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  font-family: "Vazir";
}

@media (max-height: 760px) {
  .header {
    height: 19%;
  }
  .characterMain {
    height: 39%;
  }
  .characterMain div {
    height: 85%;
  }
  .inputContiner {
    height: 22vh;
    margin-top: 5px;
  }
  .characterSmall img {
    top: -18% !important;
  }
}

@media (max-height: 670px) {
  .header {
    height: 19%;
  }
  .characterMain {
    height: 44%;
  }
  .characterMain div {
    height: 85%;
  }
}

.characterSmall {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  position: relative;
}

.characterSmall img {
  position: absolute;
  width: 22%;
  top: -9%;
  animation: getSmall 0.5s ease-in-out;
}

@keyframes getSmall {
  from {
    width: 47%;
    top: -105%;
    left: 25%;
  }
  to {
    width: 22%;
    top: -18%;
    left: 0%;
  }
}

.button {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.72px;
  text-align: right;
  color: #1b4965cc;
  border-radius: 66px;
  border-color: #58abdc;
  background-color: transparent;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  gap: 10px;
}
