.videoContainer {
  height: 45%;
  width: 100%;
}

.textContainer {
  height: 25%;
}

.videoText {
  overflow-y: scroll;
  height: 75%;
  margin-bottom: 27%;
}

.textContainer h1 {
  font-family: "Vazir";
  font-size: 20px;
  font-weight: 700;
  line-height: 31.25px;
  text-align: right;
  color: #1b4965;
  margin-top: 20px;
}

.textContainer p {
  font-family: "Vazir";
  font-size: 14px;
  font-weight: 500;
  line-height: 18.61px;
  text-align: center;
  color: #1b4965;
}

.textContainer hr {
  border-color: #5fa8d3;
}

.videoText p {
  font-family: "Vazir";
  font-size: 14px;
  font-weight: 300;
  line-height: 170%;
  color: #666666;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .videoText {
    overflow-y: scroll;
    height: 40%;
  }

  .textContainer {
    height: 15%;
  }
}
