.chat {
  display: flex;
  align-items: flex-start;
  margin-bottom: 3%;
}

.chatContent {
  background-color: #e4f3ff;
  border-radius: 9px;
  padding: 3% 4%;
}

.chatContent p {
  font-family: "Vazir";
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: right;
  color: #000000;
}

.chatContent img {
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
}
