.cardContainer {
  height: 25%;
}

.character,
.characterNotAnimated {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  position: relative;
  margin-top: 8%;
  bottom: -50px;
}

.character p,
.characterNotAnimated p {
  color: #1b4965;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  font-family: "Vazir";
}

.face {
  position: absolute;
  top: 23%;
  left: 20%;
  opacity: 1;
  animation: face 0.5s ease-in-out;
  width: 55%;
}

.faceFade {
  position: absolute;
  top: 23%;
  left: 21%;
  opacity: 1;
  animation: faceFade 0.8s ease-in-out;
  width: 53%;
}

.faceHappy {
  position: absolute;
  top: 23%;
  left: 17%;
  height: 22%;
  opacity: 1;
  animation: faceHappy 0.5s ease-in-out;
}

@keyframes faceHappy {
  from {
    top: 28%;
    left: 12%;
    opacity: 0.7;
  }

  to {
    top: 23%;
    left: 17%;
    opacity: 1;
  }
}

@keyframes faceFade {
  from {
    opacity: 0.7;
  }

  to {
    opacity: 1;
  }
}

@keyframes face {
  from {
    top: 18%;
    left: 28%;
    opacity: 0.7;
  }
  75% {
    left: 19%;
  }
  to {
    top: 23%;
    left: 20%;
    opacity: 1;
  }
}

.ice {
  position: absolute;
  left: 1%;
  top: 57%;
  width: 22%;
}
.iceMelt,
.iceMeltNotAnimated {
  position: absolute;
  left: -4px;
  top: 58%;
  width: 130%;
}

.iceMelt {
  transform: scale(1);
  animation: meltIce 0.6s ease-in-out;
}

@keyframes meltIce {
  from {
    transform: scale(0);
  }
  75% {
    transform: scale(1.07);
  }
  to {
    transform: scale(1);
  }
}

.sessionText {
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin-bottom: -10%;
  box-shadow: 0px 4px 32.7px 0px #0000003d;
  background-color: #ffffff;
  border-radius: 38px;
}

.sessionText p {
  color: #1b4965;
  font-size: 20px;
  font-weight: 500;
  line-height: 31px;
  font-family: "Vazir";
  padding: 15px;
}
