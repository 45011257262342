.text {
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
  color: #1b4965;
}

.characterMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  position: relative;
}

.characterMain p {
  font-family: "Vazir";
  font-size: 15px;
  font-weight: 500;
  line-height: 23.44px;
  text-align: center;
  color: #1b4965;
}

.characterMain img {
  width: 50%;
}
