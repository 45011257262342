.carousel {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 100%;
}
.carousel__btn {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  border: none;
  padding: 1rem;
  cursor: pointer;
  z-index: 1;
}

.carousel__btn--prev {
  left: 30%;
}
.carousel__btn--next {
  right: 30%;
}
.carousel__text {
  color: #1b4965;
  font-family: "Vazir";
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  position: absolute;
  left: 0;
  width: 95%;
  top: -9%;
  left: 1%;
}
.carousel__img:hover {
  transform: scale(1.05);
}

.legend {
  position: absolute;
  top: 53.5%;
  left: 45%;
  font-family: "Vazir";
  font-size: 15px;
  font-weight: 500;
  line-height: 23.44px;
  text-align: center;
  color: #1b4965;
}

@media (max-height: 760px) {
  .carousel__btn {
    top: 77%;
  }
  .legend {
    top: 68.5%;
  }

  .carousel__text {
    width: 85% !important;
    top: -15% !important;
    left: 4%;
  }
}
