.layout {
  display: flex;
  flex-direction: column;
  height: 91vh;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 4%;
}

.session1 {
  background-image: url("../../assets/images/sessionOneBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.session2 {
  background-image: url("../../assets/images/sessionTwoBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.session3 {
  background-image: url("../../assets/images/sessionThreeBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.session4 {
  background-image: url("../../assets/images/sessionFourBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.session5 {
  background-image: url("../../assets/images/sessionFiveBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.session6 {
  background-image: url("../../assets/images/sessionSixBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.session8 {
  background-image: url("../../assets/images/sessionEightBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.session9 {
  background-image: url("../../assets/images/sessionNineBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.session12 {
  background-image: url("../../assets/images/session15Background.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.control {
  width: 90%;
  position: absolute;
  bottom: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12%;
}

@media (min-width: 340px) and (max-width: 400px) {
  .layout {
    height: 90vh;
  }
}

@media (min-width: 768px) {
  .layout {
    padding-left: 30%;
    padding-right: 30%;
    padding-top: 2%;
  }
  .control {
    width: 41%;
  }
}

@media (min-width: 1200px) {
  .layout {
    padding-left: 33%;
    padding-right: 33%;
    padding-top: 2%;
  }
  .control {
    width: 32%;
  }
}

@media (min-width: 1400px) {
  .layout {
    padding-left: 36%;
    padding-right: 36%;
    padding-top: 2%;
  }
  .control {
    width: 26%;
  }
}

@media (min-width: 1600px) {
  .layout {
    padding-left: 40%;
    padding-right: 40%;
    padding-top: 2%;
  }
  .control {
    width: 20%;
  }
}

@media (min-width: 1800px) {
  .layout {
    padding-left: 39%;
    padding-right: 39%;
    padding-top: 2%;
  }
  .control {
    width: 22%;
  }
}
