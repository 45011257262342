.container {
  background-color: #ecf8ff;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 75%;
}

.title {
  font-family: "Vazir";
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  text-align: center;
  color: #5fa8d3;
}

.buttonContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 23%;
  justify-content: space-between;
  gap: 10px;
}

.loginButton {
  background: #58abdc;
  border-radius: 66px;
  width: 100%;
  padding: 18px;
  font-family: "Vazir";
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.registerButton {
  border: 1px solid #58abdc;
  border-radius: 66px;
  width: 100%;
  padding: 18px;
  background-color: transparent;
  font-family: "Vazir";
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: center;
  color: #58abdc;
}

@media (min-width: 768px) {
  .layout {
    padding-left: 30%;
    padding-right: 30%;
    padding-top: 2%;
  }
}

@media (min-width: 1200px) {
  .layout {
    padding-left: 33%;
    padding-right: 33%;
    padding-top: 2%;
  }
}

@media (min-width: 1400px) {
  .layout {
    padding-left: 36%;
    padding-right: 36%;
    padding-top: 2%;
  }
}

@media (min-width: 1600px) {
  .layout {
    padding-left: 40%;
    padding-right: 40%;
    padding-top: 2%;
  }
}

@media (min-width: 1800px) {
  .layout {
    padding-left: 39%;
    padding-right: 39%;
    padding-top: 2%;
  }
}
