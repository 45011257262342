.inputControl,
.inputControlActive {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 85%;
  position: relative;
}

.inputControl label,
.inputControlActive label {
  font-family: "Vazir";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: right;
  color: #2278ab;
}

.inputControl input {
  border: 1px solid #5599c3;
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: right;
  color: #31373a;
  padding: 18px;
  border-radius: 66px;
}

.inputControlActive input {
  border: 1px solid #5599c3;
  background: #58abdc26;
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: right;
  color: #31373a;
  padding: 18px;
  border-radius: 66px;
}

.inputControlActive input:focus {
  background: #00a0ff66;
}

.password {
  -webkit-text-security: disc !important;
}

.passEye {
  position: absolute;
  left: 5%;
  top: 57%;
  height: 25px;
}
