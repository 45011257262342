.container {
  background-color: #ecf8ff;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 85%;
}

.title {
  font-family: "Vazir";
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  text-align: center;
  color: #5fa8d3;
}

.card {
  background-color: #ffffff;
  border-radius: 61px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 70%;
  position: relative;
}

.card .character {
  position: absolute;
  top: -10%;
  height: 25%;
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 82%;
}

.dontHaveAccount {
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: right;
  color: #2278ab;
}

.dontHaveAccount a {
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: right;
  color: #00649f;
  text-decoration: none;
}

@media (min-width: 768px) {
  .layout {
    padding-left: 30%;
    padding-right: 30%;
    padding-top: 2%;
  }
}

@media (min-width: 1200px) {
  .layout {
    padding-left: 33%;
    padding-right: 33%;
    padding-top: 2%;
  }
}

@media (min-width: 1400px) {
  .layout {
    padding-left: 36%;
    padding-right: 36%;
    padding-top: 2%;
  }
}

@media (min-width: 1600px) {
  .layout {
    padding-left: 40%;
    padding-right: 40%;
    padding-top: 2%;
  }
}

@media (min-width: 1800px) {
  .layout {
    padding-left: 39%;
    padding-right: 39%;
    padding-top: 2%;
  }
}
