.characterMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55%;
  position: relative;
}

.characterMain p {
  color: #ffffff;
  font-family: "Vazir";
  font-size: 15px;
  font-weight: 500;
  line-height: 23.44px;
  text-align: center;
}

.marginTop {
  margin-top: 32%;
}
.marginBottom {
  margin-bottom: 25%;
}

.characterMain img {
  width: 50%;
}

.sessionText {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0px 4px 32.7px 0px #0000003d;
  background-color: #ffffff;
  border-radius: 38px;
}

.sessionText p {
  font-family: "Vazir";
  font-size: 15px;
  padding: 15px;
  font-weight: 600;
  line-height: 18.15px;
  text-align: center;
  color: #000000;
}

.thoughts {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.thoughts img {
  width: 100%;
}

.mainInput,
.mainInputReverse {
  position: absolute;
  left: 14%;
  bottom: -23%;
}

.mainInput {
  animation: thoughts 0.5s ease-in-out;
}

.mainInput img {
  width: 100% !important;
}

.input {
  position: absolute;
  left: 1%;
  height: 74%;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  text-align: center;
  width: 95%;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.54px;
  text-align: right;
  color: #1b4965c2;
}

@keyframes thoughts {
  from {
    bottom: -51%;
    transform: scale(0.4);
  }
  to {
    bottom: -23%;
    transform: scale(1);
  }
}

.p7Animation {
  animation: thoughtsReverseP7 0.5s ease-in-out;
}

@keyframes thoughtsReverseP7 {
  from {
    bottom: -51%;
    transform: scale(0.4);
  }
  to {
    bottom: -90%;
    transform: scale(1);
  }
}
.p8Animation {
  animation: thoughtsReverseP8 0.5s ease-in-out;
}

@keyframes thoughtsReverseP8 {
  from {
    bottom: -51%;
    transform: scale(0.4);
  }
  to {
    bottom: -175%;
    transform: scale(1);
  }
}

.p9Animation {
  animation: thoughtsReverseP9 0.5s ease-in-out;
}

@keyframes thoughtsReverseP9 {
  from {
    bottom: -51%;
    transform: scale(0.4);
  }
  to {
    bottom: -108%;
    transform: scale(1);
  }
}

.modalText {
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: right;
  color: #31373a;
}

.modalButton {
  background-color: #00a0ff;
  border: none;
  border-radius: 37px;
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: center;
  color: #ffffff;
  padding: 15px;
  width: 80%;
}
