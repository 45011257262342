.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000045;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: #ffffff;
  height: auto;
  width: 85%;
  margin: auto;
  padding: 5%;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  gap: 25px;
}

@media (min-width: 992px) {
  .modal {
    width: 15%;
    padding: 3%;
  }
}
