.videoContainer {
  height: 45%;
  width: 100%;
}

.textContainer {
  height: 25%;
}

.videoText {
  overflow-y: scroll;
  height: 75%;
  margin-bottom: 27%;
}

.textContainer h1 {
  font-family: "Vazir";
  font-size: 20px;
  font-weight: 700;
  line-height: 31.25px;
  text-align: right;
  color: #1b4965;
  margin-top: 20px;
}

.textContainer p {
  font-family: "Vazir";
  font-size: 14px;
  font-weight: 500;
  line-height: 18.61px;
  text-align: center;
  color: #1b4965;
}

.textContainer hr {
  border-color: #5fa8d3;
}

.videoText p {
  font-family: "Vazir";
  font-size: 14px;
  font-weight: 300;
  line-height: 170%;
  color: #666666;
  margin-bottom: 25px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  gap: 5%;
}

.surveyContainer {
  background-color: #edf8ff;
  border-radius: 20px;
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;
}

.surveyContainer p {
  font-family: "Vazir";
  font-size: 20px;
  font-weight: 700;
  line-height: 31.25px;
  text-align: right;
  color: #1b4965;
}

.surveyText p {
  font-family: "Vazir";
  font-size: 12px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: right;
  color: #1b4965;
}

@media (min-width: 992px) {
  .videoText {
    overflow: unset;
    height: 40%;
  }

  .textContainer {
    height: 15%;
  }
}

.voteCharacter {
  height: 20%;
  width: 100%;
}

@media (max-height: 760px) {
  .voteCharacter {
    height: 25%;
  }
}

.voteCharacter img {
  height: 100%;
  width: 100%;
}

.mainVote {
  height: 65%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.mainVote textarea {
  background-color: #ffffffbf;
  color: #1b4965;
  border: none;
  border-radius: 15px;
  padding: 10px;
}

.mainVote textarea::placeholder {
  font-family: "Vazir";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: right;
  color: #1b4965cc;
}

.mainVote input {
  background-color: #ffffff80;
  border-radius: 15px;
}

.mainVote label {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  text-align: right;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15%;
}

.customRadio {
  left: 10px;
  top: 6px;
  cursor: pointer;
  width: 55px;
  height: 55px;
  background-color: #ffffff80;

  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.customRadio img {
  width: 85%;
  height: 85%;
  z-index: 1000;
}

.customRadio::after {
  content: "";
  width: 55px;
  height: 55px;
  background-color: #ffffff80;
  position: absolute;
  border-radius: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
}

.mainVote input {
  visibility: hidden;
}

.mainVote input:checked + span {
  box-shadow: 0px 0px 16.8px 0px #00000040;
}

.mainVote input:checked + span:after {
  opacity: 1;
  background-color: #26aefe;

  border: 3px solid #2a8bc4;
}
